import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi"
import { Country, DeliveryAddress } from "../domain/interfaces"
import { CheckoutState, ShopState } from "../state/state.interface"
import { confirmAddress, confirmBasket } from "../usecases/checkout"


const connector = connect((state: ShopState) => ({
    checkout: state.checkout
}))

const ACheckoutAddress: React.FunctionComponent<{checkout: CheckoutState, dispatch: any}> = ({checkout, dispatch}) => {
    
    const api = new httpApiGateway()
    const [countries, setCountries] = useState<Country[]>([])
    const [address, setAddress] = useState<DeliveryAddress>(checkout.address as DeliveryAddress)
    const [focusCountries, setFocusCountries] = useState<boolean>(false)
    const [countriesFiltered, setCountriesFilters] = useState<Country[]>([])

    useEffect(() => {
        api.getCountries().then(setCountries)
        setAddress(checkout.address  as DeliveryAddress)
    }, [checkout.address])

    const handleChange = (key: string) => (event: any) => {
        setAddress({...address, [key]: event.target.value})
    }

    const onTypingCountry = (event: any) => {
        if (event.target.value && event.target.value.length > 0) {
          setCountriesFilters(countries.filter(c => c.name.toLowerCase().includes(event.target.value.toLowerCase())))
        } else {
          setCountriesFilters(countries)
        }
        setAddress({
          ...address,
          countryName: event.target.value
        })
      }
    
    const onSelectCountry = (country: Country) => {
        setAddress({
            ...address,
            countryName: country.name,
            idCountry: country.id
        })
        setFocusCountries(true)
    }

    return address && <div className="pt-10 md:py-24 md:mx-24 flex flex-col md:flex-row items-top items-center justify-center md:space-x-10 mx-auto">

    <div className="flex flex-col w-40 md:w-96 mx-auto md:mx-1 pb-10">
      <img className="object-cover w-full" src="/addressIllustration.svg"/>
    </div>

    <div className="pt-5 bg-white shadow rounded px-8 pb-8 mb-4 flex flex-col md:w-96 w-80 mx-auto border border-gray-200">
    <h1 className="text-xl pb-2 text-brand-primary"> Votre adresse </h1>
    <p className="pb-5 text-gray-600">
        L'adresse sélectionnée sera utilisée à la fois comme adresse personnelle (pour la facturation) et comme adresse de livraison.
    </p>
    <div className="mb-4">
      <div className="flex flex-row">
        <div className="w-1/2">
          <label className="block text-grey-darker text-sm font-bold mb-2">
            Mon prénom
          </label>
          <input onChange={handleChange('firstname')} value={address.firstname} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="text" placeholder="Prénom" />
        </div>
        <div className="w-1/2 pl-2">
          <label className="block text-grey-darker text-sm font-bold mb-2">
            Mon nom
          </label>
          <input  onChange={handleChange('lastname')}  value={address.lastname}  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="text" placeholder="Nom" />
        </div>
      </div>
    </div>
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2">
        Mon numéro de téléphone
      </label>
      <input  onChange={handleChange('phoneNumber')}  value={address.phoneNumber}  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="email" placeholder="+262 123 456" />
    </div>
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2">
        Mon adresse
      </label>
      <input onChange={handleChange('address')}  value={address.address}  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="email" placeholder="134 champs élysées" />
    </div>
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2">
        Mon adresse suite
      </label>
      <input  onChange={handleChange('address2')}  value={address.address2}  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="email" placeholder="Appartement 15" />
    </div>
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2">
        Ville
      </label>
      <input  onChange={handleChange('city')}  value={address.city}  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="email" placeholder="Paris" />
    </div>
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2">
        Code Postal
      </label>
      <input onChange={handleChange('zipCode')}  value={address.zipCode} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="email" placeholder="75008" />
    </div>
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2">
        Pays
      </label>
      <div className="flex flex-col items-center relative">
          <div className="w-full  svelte-1l8159u">
              <div className="my-2 bg-white p-1 flex border border-gray-200 rounded svelte-1l8159u">
                  <div className="flex flex-auto flex-wrap"></div>
                  <input onChange={onTypingCountry} onClick={()=>setFocusCountries(true)} onFocus={()=>setFocusCountries(true)} onBlur={()=>setTimeout(()=>setFocusCountries(false), 500)} value={address.countryName} className="p-1 px-2 appearance-none outline-none w-full text-gray-800  svelte-1l8159u" />
                  <div onClick={()=>setFocusCountries(true)} className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u">
                      <button  className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
                              <polyline points="18 15 12 9 6 15"></polyline>
                          </svg>
                      </button>
                  </div>
              </div>
          </div>
          { focusCountries && <div style={{'top': '100%'}} className="absolute shadow top-100 z-40 w-full lef-0 rounded max-h-select">
              <div className="flex flex-col w-full overflow-y-auto max-h-96">
                  { countriesFiltered.map( country => (<div key={country.id} onClick={() => onSelectCountry(country)} className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100 ">
                      <div className="flex w-full items-center p-2 pl-2 border-transparent bg-white hover:bg-gray-300 border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                          <div className="w-full items-center flex">
                              <div className="mx-2 leading-6  "> {country.name} </div>
                          </div>
                      </div>
                  </div>)) }
              </div>
          </div> }
      </div>
    </div>

    { checkout.status == 'error' && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
      <div className="text-md font-normal max-w-full flex-initial">
          {checkout.error}
      </div>
    </div> }

    <div className="flex space-x-4">
      <button onClick={() => dispatch(confirmAddress(address))} className="bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded" type="button">
        Valider
      </button>
    </div> 

    </div> 


    </div>
}

export const CheckoutAddress = connector(ACheckoutAddress)