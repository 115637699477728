import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { CustomerInfo } from "../domain/interfaces"
import { CheckoutState, ShopState } from "../state/state.interface"
import { confirmBasket } from "../usecases/checkout"
import { Svg } from "../components/svgIcons"
import { removeProductFromBasket } from "./../usecases/basket"

const connector = connect((state: ShopState) => ({
    checkout: state.checkout
}))

const ACheckoutBasket: React.FunctionComponent<{checkout: CheckoutState, dispatch: any}> = ({checkout, dispatch}) => {

    const [customerInfo, setCustomerInfo] = useState<CustomerInfo>(checkout.info || {
        message: '',
        poignet: '',
        cheville: '',
        cou: ''
    })

    useEffect(() => {
        if (checkout.info) setCustomerInfo(checkout.info)
    }, [checkout.info])
    
    const handleChange = (key: string) => (event: any) => {
        setCustomerInfo({...customerInfo, [key]: event.target.value})
    }
    
    return checkout.basket && <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 mx-auto my-10" style={{'maxWidth': "1024px"}}>
        <div className="sm:flex sm:items-start">
        <div className="ml-4 text-left">
                <h3 className="text-2xl leading-6 font-medium text-gray-900 text-brand-primary" id="modal-title">
                    Résumé de votre commande
                </h3>
                <div className="mt-5 w-full">
                    { checkout.basket.products.length == 0 && <p className="text-sm text-gray-500">
                        Votre Panier est vide pour le moment
                    </p> }
                    { checkout.basket.products.filter(product => product.quantity > 0).map((product) => 
                        <div className="mb-4 w-full flex flex-row" key={product.product.id}> 
                            <img className="h-20 w-20 rounded-sm" src={product.product.coverImage.thumbnail.imgUrl} />
                            <div className="ml-2 w-full"> 
                                <h1 className="text-xl -mb-1 -mt-1"> {product.quantity} {product.product.name} </h1> 
                                <div className="text-xs text-gray-500"> {product.product.reference} </div>
                                <div className="mt-1 text-xs"> {product.product.priceWithTaxFormat} {product.product.currencySymbol} </div>
                                {product.product.stockAvailable <= 0 && <div className="text-xs text-red-500 font-bold uppercase"> Cet article n'est plus disponible ! </div> }
                            </div>
                        </div>
                    )}
                    {!!checkout.basket.priceTotalFormated && (<span> Montant Total {checkout.basket.priceTotalFormated} </span>)}
                </div>

                { checkout.basket.products.length > 0 &&  <>
                <div className="mt-5 mb-5">
                    <p> Pour que vos bijoux soit parfaitement ajustés vous avez la possibilité de me communiquer les informations suivantes. </p>
                    <div className="w-1/2 mt-5 mb-4">
                    <label className="block text-brand-primary text-sm font-bold mb-2">
                        Tour de cou
                    </label>
                    <input onChange={handleChange('cou')} value={customerInfo.cou} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="text" placeholder="en centimètre" />
                    </div>
                    <div className="w-1/2 mb-4">
                    <label className="block text-brand-primary text-sm font-bold mb-2">
                        Tour de poignet
                    </label>
                    <input onChange={handleChange('poignet')} value={customerInfo.poignet} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="text" placeholder="en centimètre" />
                    </div>
                    <div className="w-1/2 mb-4">
                    <label className="block text-brand-primary text-sm font-bold mb-2">
                        Tour de cheville
                    </label>
                    <input onChange={handleChange('cheville')} value={customerInfo.cheville} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" type="text" placeholder="en centimètre" />
                    </div>
                    <div className="w-full mb-4">
                    <label className="block text-brand-primary text-sm font-bold mb-2">
                        Autre remarques
                    </label>
                    <textarea onChange={handleChange('message')} value={customerInfo.message} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" placeholder="votre message" />
                    </div>
                </div>
                
                { checkout.status == 'error' && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
                    <div className="text-md font-normal max-w-full flex-initial">
                        {checkout.error}
                    </div>
                </div> }

                <button onClick={() => dispatch(confirmBasket(customerInfo))} className="mt-10 bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded w-40 text-center" type="button">
                    {   checkout.status === 'loading' && <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                    }
                    { (checkout.status === 'success' || checkout.status === 'error') && <div> Valider </div> }
                </button>

                </> }
            </div>
        </div>
    </div>
}

export const CheckoutBasket = connector(ACheckoutBasket)