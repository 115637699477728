import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi"
import { Svg } from "./svgIcons"
import { Basket, Country } from "../domain/interfaces"
import { CheckoutState, ShopState } from "../state/state.interface"
import { confirmBasketCheckout, confirmCarrier, errorPayment, completePayment } from "../usecases/checkout"

const connector = connect((state: ShopState) => ({
  checkout: state.checkout
}))

const ACheckoutPayment: React.FunctionComponent<{ checkout: CheckoutState, dispatch: any }> = ({ checkout, dispatch }) => {

  // useEffect(() => {
  //   if (checkout.basket && checkout.basket.checkout_data && sumupMounted === false) {
  //     setTimeout( () => (window as any).SumUpCard.mount({
  //       checkoutId: (checkout.basket as Basket).checkout_data,
  //       locale: 'fr-FR',
  //       onResponse: (type: any, body: any) => {
  //         console.log(type)
  //         console.log(body)
  //         if (type === 'error' || type === 'success') {
  //           dispatch(completePayment)
  //         }
  //       },
  //       onLoad: () => {
  //         setLoadingSumup(false)
  //         setSumupMounted(true)
  //       }
  //     }), 2000)
  //   }
  // }, [checkout.basket?.checkout_data])

  const onPay = () => {
    dispatch(confirmBasketCheckout)
  }

  
  return checkout.basket && <> <div className="pt-10 md:py-24 md:mx-24 flex flex-col md:flex-row items-top justify-center md:space-x-10 mx-auto">

    <div className="pt-5 bg-white shadow rounded px-8 pb-8 mb-4 flex flex-col md:w-96 w-80 mx-auto md:mx-0 border border-gray-200">
            <p className="mb-10 text-brand-primary text-2xl">
                Résumé de votre commande
            </p>
            { checkout.basket.products.filter(product => product.quantity > 0).map((product) => 
                    <div className="mb-4 w-full flex flex-row" key={product.product.id}> 
                        <img className="h-20 w-20 rounded-sm" src={product.product.coverImage.thumbnail.imgUrl} />
                        <div className="ml-2 w-full"> 
                            <h1 className="text-xl -mb-1 -mt-1"> {product.quantity} {product.product.name} </h1> 
                            <div className="text-xs text-gray-500"> {product.product.reference} </div>
                            <div className="mt-1 text-xs"> {product.product.priceWithTaxFormat} {product.product.currencySymbol} </div>
                        </div>
                    </div>
            )}
            <span> Prix total : {checkout.basket.priceTotalProductFormated} </span>
            <span> Livraison: {checkout.basket.priceTotalDeliveryFormated} </span>
            <span className="font-bold"> Total: {checkout.basket.priceTotalFormated} </span>
      </div> 

      <div className="px-8 pt-6 pb-8 mb-4 flex flex-col md:w-96 mx-auto w-full md:w-96 justify-center">
            
            { checkout.widgetPaymentStatus != 'mounted'  && <img className="object-cover w-full" src="/paymentIllustration.svg"/> }


            { checkout.basket?.checkout_data && checkout.basket?.checkout_status != 'FAILED' && 
              <div>
                <div className="w-96 mt-10 mx-auto height:500px" dangerouslySetInnerHTML={{__html:'<div id="sumup-card" class="w-full"></div>'}} /> 
              </div>
            }
            
            { checkout.widgetPaymentStatus == 'mounting' && <div className="w-full flex flex-col justify-center items-center">
                <div className="text-gray-800 mx-auto"> chargement du terminal de paiement </div>
                <div className="spinner mx-auto">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
            </div> }

            { checkout.status == 'loading' && <div className="w-full flex flex-col justify-center items-center">
                <div className="text-gray-800 mx-auto"> opération en cours ... </div>
                <div className="spinner mx-auto">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
            </div> }

            
            { checkout.status == 'error' && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
              <div className="text-md font-normal max-w-full flex-initial">
                  {checkout.error}
              </div>
            </div> }

            <p className="text-gray-700 mt-4">
                <span className="text-brand-primary inline-block h-4 pr-1"> <Svg icon="shield" /> </span> 
                Le paiement par carte bleue est sécurisé et chiffré de bout en bout
            </p>
            
  
            <div className="flex space-x-4 mt-5 w-full justify-center md:justify-start">
              { (!checkout.basket?.checkout_data || checkout.basket?.checkout_status == 'FAILED') &&  <button onClick={onPay} className="bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded" type="button">
                Payer par carte bancaire
              </button> }
            </div>
      </div> 

  </div>



  </>
}

export const CheckoutPayment = connector(ACheckoutPayment)