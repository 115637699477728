import { info } from "autoprefixer";
import { CustomerInfo, DeliveryAddress } from "../domain/interfaces";
import * as actionCreators from "../state/actionCreators";
import {ThunkResult} from "../state/configureStore";


export const initCheckout: ThunkResult<Promise<void>> = async (dispatch, state, {apiGateways, navigate, mountPaymentGateway}) => {
    const logged = await apiGateways.imLogged()
    if (!logged) {
        navigate('/signup')
    } else {
        const address = await apiGateways.getDeliveryAddress()
        let basket = await apiGateways.getBasket()
        let customerInfo = await apiGateways.getCustomerInfo()

        const outOfStock: boolean = basket.products.map(product => product.product.stockAvailable).filter(stock => stock <= 0).length > 0
        if (outOfStock) {
            dispatch(actionCreators.Actions.checkoutError('Certains produits de votre panier ne sont plus en stock. Merci de les retirer'))
            navigate('/account')
            return
        }

        dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
        dispatch(actionCreators.Actions.checkoutAddressFetched(address))
        
        if (customerInfo != null) {
            dispatch(actionCreators.Actions.checkoutCustomerInfoFetched(customerInfo))
        }
        
        if (customerInfo != null && basket.checkout_data) {

            if (basket.checkout_status == 'PAID') {
                await apiGateways.basketOrdered()
                dispatch(actionCreators.Actions.checkoutPaymentSuccess())
            } 

            if (basket.checkout_status == 'PENDING' || basket.checkout_status == 'FAILED') {
                const options = await apiGateways.getDeliveryOptions()
                let carrierOption = options.filter(option => option.carrier.id == basket.carrierId)[0]
                dispatch(actionCreators.Actions.checkoutDeliveryOptionsSelected(carrierOption.carrier.name))
                basket = await apiGateways.getBasket()
                dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
            } 

            if(basket.checkout_status == 'PENDING'){
                let destroyCallback = mountPaymentGateway(
                    basket.checkout_data as string,
                    ()=>dispatch(actionCreators.Actions.checkoutPaymentWidgetMounted()),
                    ()=>dispatch(completePayment)
                )
                dispatch(actionCreators.Actions.checkoutMountPaymentWidget(destroyCallback))
            }
            
        }
    }
}

export const confirmBasket = (info: CustomerInfo): ThunkResult<Promise<void>> => async (dispatch, state, {apiGateways, navigate}) => {
    if (state().checkout.basket?.products.length == 0) {
        dispatch(actionCreators.Actions.checkoutError('Vous ne pouvez pas commander un panier vide'))
    } else {
        dispatch(actionCreators.Actions.checkoutProcessing())
        dispatch(actionCreators.Actions.checkoutBasketConfirm())
        await apiGateways.putCustomerInfo(info)
        const address = await apiGateways.getDeliveryAddress()
        dispatch(actionCreators.Actions.checkoutAddressFetched(address))
    }

}

export const confirmAddress = (address: DeliveryAddress): ThunkResult<Promise<void>>  => async (dispatch, state, {apiGateways}) => {
    dispatch(actionCreators.Actions.checkoutProcessing())
    let response = await apiGateways.onPostDeliveryAddress(address)
    if (response.success) {
        dispatch(actionCreators.Actions.checkoutAddressPosted(address))
        const deliveryOptions = await apiGateways.getDeliveryOptions()
        dispatch(actionCreators.Actions.checkoutDeliveryOptionsFetched(deliveryOptions))
    } else {
        dispatch(actionCreators.Actions.checkoutError(response.error as string))
    }
    
}

export const confirmCarrier = (carrierId: number): ThunkResult<Promise<void>>  => async (dispatch, state, {apiGateways}) => {
    dispatch(actionCreators.Actions.checkoutProcessing())
    
    let options = state().checkout.deliveryOptions
    let carrierOption = options.find(option => option.carrier.id == carrierId)

    if (!carrierOption) {
        dispatch(actionCreators.Actions.checkoutError('Merci de choisir un mode de livraison'))
    } else {
        await apiGateways.putDeliveryOptions(carrierId)
        const basket = await apiGateways.getBasket()
        dispatch(actionCreators.Actions.checkoutDeliveryOptionsSelected(carrierOption.carrier.name))
        dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
    }
    
}

export const confirmBasketCheckout: ThunkResult<Promise<void>> = async (dispatch, state, {apiGateways, navigate, mountPaymentGateway}) => {
    await apiGateways.basketCheckout()
    let basket = await apiGateways.getBasket()
    dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
    let destroyCallback = mountPaymentGateway(
        basket.checkout_data as string,
        ()=>dispatch(actionCreators.Actions.checkoutPaymentWidgetMounted()),
        ()=>dispatch(completePayment)
    )
    dispatch(actionCreators.Actions.checkoutMountPaymentWidget(destroyCallback))
    dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
}

export const errorPayment = (error: string): ThunkResult<Promise<void>>  => async (dispatch, state, {apiGateways}) => {
    dispatch(actionCreators.Actions.checkoutError(error))
}

export const completePayment: ThunkResult<Promise<void>> = async (dispatch, state, {apiGateways, navigate, refreshPaymentTimeoutDelay}) => {
   const basketId = state().checkout.basket?.id
   if (basketId) {
        dispatch(actionCreators.Actions.checkoutProcessing())

        let basket = await apiGateways.getBasketById(basketId)
        
        if (basket.checkout_status === 'PAID') {
            await apiGateways.basketOrdered()
            let basket = await apiGateways.getBasketById(basketId)
            dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
            if (basket.order && basket.order.id && basket.order.id > 0) {
                dispatch(actionCreators.Actions.checkoutPaymentSuccess())
                let info = await apiGateways.getCustomerInfo()
                if (info != null) {
                    apiGateways.putOrderMessage(basket.order.id, info)
                }
            } else {
                dispatch(actionCreators.Actions.checkoutError('Une erreur est survenue lors de la commande merci de nous contacter'))
            }
        } 
        
        if (basket.checkout_status === 'FAILED') {
            dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
            dispatch(actionCreators.Actions.checkoutError('Une erreur est survenue lors du paiement. La transaction a été annulée.'))
            state().checkout.destroyPaymentWidgetCallback()
            dispatch(actionCreators.Actions.checkoutPaymentWidgetDestroy())
        }   

        if (basket.checkout_status === 'PENDING') {
            await new Promise(resolve => setTimeout(resolve, refreshPaymentTimeoutDelay));
            basket = await apiGateways.getBasketById(basketId)
            if (basket.checkout_status === 'PAID') {
                await apiGateways.basketOrdered()
                dispatch(actionCreators.Actions.checkoutPaymentSuccess())
                let basket = await apiGateways.getBasketById(basketId)
                if (basket.order && basket.order.id) {
                    let info = await apiGateways.getCustomerInfo()
                    if (info != null) {
                        apiGateways.putOrderMessage(basket.order.id, info)
                    }
                }
            } 
            if (basket.checkout_status === 'FAILED' || basket.checkout_status === 'PENDING') {
                dispatch(actionCreators.Actions.checkoutError('Une erreur est survenue lors du paiement. La transaction a été annulée.'))
                let basket = await apiGateways.getBasket()
                dispatch(actionCreators.Actions.checkoutBasketFetched(basket))
                state().checkout.destroyPaymentWidgetCallback()
                dispatch(actionCreators.Actions.checkoutPaymentWidgetDestroy())
            }
        }
   }
}

