import React, {useEffect, useState} from "react"
import { httpApiGateway } from "../../adapters/secondary/httpEshopApi";
import { Country, DeliveryAddress, StaticContext } from "../../domain/interfaces";
import Page from "../../templates/page"
import { CheckoutBasket } from "../../components/checkoutBasket"
import { CheckoutAddress } from "../../components/checkoutAddress"
import { CheckoutCarrier } from "../../components/checkoutCarrier"
import { CheckoutPayment } from "../../components/checkoutPayment"
import { CheckoutPaymentSuccess } from "../../components/checkoutPaymentSuccess"
import { CheckoutPaymentCanceled } from "../../components/checkoutPaymentCanceled"
import {connect, } from "react-redux"
import { CheckoutState, ShopState } from "../../state/state.interface";
import { confirmAddress, confirmBasket, initCheckout } from "../../usecases/checkout";
import { Helmet } from "react-helmet"
import { Svg } from "../../components/svgIcons"
import { check } from "prettier";

const connector = connect((state: ShopState) => ({
    checkout: state.checkout
}))

const Checkout = ({dispatch, checkout}: {dispatch: any, checkout: CheckoutState}) => {

  const api = new httpApiGateway()
  const [context, setContext] = useState<StaticContext | null>(null)

  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
    dispatch(initCheckout)
  }, []) // given empty array useEffect run only once

  const goBackAddress = () => {
    if (checkout.step == 'carrier' || checkout.step == 'payment') {
      dispatch(confirmBasket)
    }
  }

  const goBackCarrier = () => {
    if (checkout.step == 'payment' && checkout.address) {
      dispatch(confirmAddress(checkout.address))
    }
  }

  const classNameProgression = ' transition text-md text-white bg-brand-primary border-2 border-brand-primary-lighter w-8 h-8 rounded-full flex align-middle justify-center items-center cursor-pointer hover:bg-brand-primary-darkest'

  return <Page pageContext={context} hideBasket={true}>

    <div className="w-full">

     <Helmet>
        <script src="https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js"  type="text/javascript"/>
     </Helmet>

    { ['address', 'carrier', 'payment'].indexOf(checkout.step) >= 0 && <div className="w-full flex flex-row justify-center mt-10">
        <div onClick={goBackAddress} className="flex flex-col opacity-100 items-center"> 
            <div className={(checkout.step == 'address'? 'shadow-xl ring ring-red-200' : '') + classNameProgression}> 
              { checkout.step == 'address' ? 1 : <Svg icon="check"/> }
            </div>
            <div className="text-brand-primary-lighter text-md font-bold h-4 flex items-center px-4 w-full text-center mt-4"> Adresse </div>
        </div>
        <div onClick={goBackCarrier} className="flex flex-col opacity-100 items-center pulse"> 
            <div className={(checkout.step == 'carrier'? 'shadow-xl  ring ring-red-200' : '') + classNameProgression}> 
            { (checkout.step == 'address' || checkout.step == 'carrier' ) ? 2 : <Svg icon="check"/> }
            </div>
            <div className="text-brand-primary-lighter text-md font-bold h-4 flex items-center px-4 w-full text-center mt-4"> Livraison </div>
        </div>
        <div onClick={() => {}} className="flex flex-col opacity-100 items-center"> 
            <div className={(checkout.step == 'payment'? 'shadow-xl ring ring-red-200' : '') + classNameProgression}> 
            { (checkout.step == 'address' || checkout.step == 'carrier' || checkout.step == 'payment' ) ? 3 : <Svg icon="check"/> }
            </div>
            <div className="text-brand-primary-lighter text-md font-bold h-4 flex items-center px-4 w-full text-center mt-4"> Paiement </div>
        </div>
    </div> }

    {checkout.status == 'loading' && <div className="py-20 bg-white" style={{'zoom': 2}}> 
                <div className="spinner mx-auto">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
    </div> }
    
    {checkout.step === 'setup' && <CheckoutBasket />}
    {checkout.step === 'address' && <CheckoutAddress />}
    {checkout.step === 'carrier' && <CheckoutCarrier />}
    {checkout.step === 'payment' && <CheckoutPayment />}
    {checkout.step === 'payed' && <CheckoutPaymentSuccess />}
    {checkout.step === 'canceled' && <CheckoutPaymentCanceled />}

    </div>

  </Page>
}


export default connector(Checkout)