import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi"
import { Svg } from "./svgIcons"
import { Basket, Country } from "../domain/interfaces"
import { CheckoutState, ShopState } from "../state/state.interface"
import { confirmBasketCheckout, confirmCarrier, errorPayment, completePayment } from "../usecases/checkout"

const connector = connect((state: ShopState) => ({
  checkout: state.checkout
}))

const ACheckoutPaymentCanceled: React.FunctionComponent<{ checkout: CheckoutState, dispatch: any }> = ({ checkout, dispatch }) => {

  return <div className="pt-10 md:mx-24 flex flex-row items-center justify-center space-x-2">

    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col md:w-96 w-1/2">
            <p className="mb-10 text-brand-primary text-2xl">
                Votre commande a été annulée ! ❌
            </p>
            <p className="mb-10 text-gray-800">
                Une erreur est survenue lors de la transaction
            </p>
      </div> 
  </div>
}

export const CheckoutPaymentCanceled = connector(ACheckoutPaymentCanceled)