import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { CheckoutState, ShopState } from "../state/state.interface"
import { confirmCarrier } from "../usecases/checkout"

const connector = connect((state: ShopState) => ({
  checkout: state.checkout
}))

const ACheckoutCarrier: React.FunctionComponent<{ checkout: CheckoutState, dispatch: any }> = ({ checkout, dispatch }) => {

  const [selectedCarrierId, setCarrierId] = useState<number>(0)

  return <div className="pt-10 md:py-24 md:mx-24 flex flex-col md:flex-row items-top justify-center md:space-x-10 mx-auto">


    <div className="flex flex-col w-40 md:w-96 mx-auto md:mx-1 pb-10">
      <img className="object-cover w-full" src="/deliveryIllustration.svg"/>
    </div>


    <div className="pt-5 bg-white shadow rounded px-8 pb-8 mb-4 flex flex-col md:w-96 w-80 mx-auto border border-gray-200">

      <p className="mt-2 mb-5 text-gray-800">
        Veuillez choisir un mode de Livraison
      </p>

      {checkout.deliveryOptions.map(option => (<div key={option.carrier.id} className="mb-4">
        <div className="flex flex-row">
          <div className="w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              <input type="radio" checked={(selectedCarrierId === option.carrier.id)} onChange={() => setCarrierId(option.carrier.id)} /> {option.carrier.name}
            </label>
            <span> Frais de livraison {option.totalFormated} </span> <br/>
            <span className="text-gray-800"> Délai {(option.carrier as any).delay} </span>
          </div>
        </div>
      </div>))
      }

    { checkout.status == 'error' && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
      <div className="text-md font-normal max-w-full flex-initial">
          {checkout.error}
      </div>
    </div> }


     <div className="flex space-x-4">
        <button onClick={() => dispatch(confirmCarrier(selectedCarrierId))} className="bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded" type="button">
          Valider
        </button>
      </div> 

    </div>


  </div>
}

export const CheckoutCarrier = connector(ACheckoutCarrier)